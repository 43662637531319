import { db } from "../../firebase";
import "../../css/HomePage.css";

import FilterForm from "../filters/index";

import AddURLForm from "./components/addURLForm";
import SearchBar from "./components/searchBar";
import UrlTable from "./components/UrlTable";
import { useTableState } from "./State";
import { useSortConfigUpdate, useSortedUrls } from "./handlers/sorting";
import {
  handleInputChange,
  handleKeyDown,
  searchResults,
} from "./handlers/search";
import {
  handleFilterClick,
  handleFilterClose,
  handleSignOut,
} from "./handlers/user";
import {
  handleAddUrl,
  handleEarlyMonitorToggle,
  handleRemoveUrl,
} from "./handlers/urlManipulation";
import TicketDrops from "../mailbox/ticketDrops";
import { useEventContext } from "./UserDataContext";
import { IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import { Link } from "react-router-dom";
import FilterFormOld from "../filtersOld";
import NotAllowed from "../NotAllowed";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: "absolute",
    left: "10px",
    top: "70px",
    backgroundColor: "#121212",
    width: "45px", // Remove background
    "&:hover": {
      backgroundColor: "black", // Remove background on hover
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  logoutIcon: {
    "&:hover": {
      backgroundColor: "black",
    },
    width: "26px", // Adjust the size as needed
    height: "26px", // Adjust the size as needed
  },
}));

const HomePage = ({ handleShowTicketDrops }) => {
  const classes = useStyles();
  const userContext = useEventContext();

  const {
    user,
    eventsInfo,
    urls,
    TotalUrls,
    early,
    totalEarly,
    mutedEvents,
    editFilters,
    showTable,
    mainUser,
    formattedFiltersRef,
    vividIds,
    phantomAllowed,
  } = userContext;

  if (editFilters && showTable) {
    handleShowTicketDrops(true);
  } else {
    handleShowTicketDrops(false);
  }

  const stubhubIds = userContext.stubHubInfo;
  const filtersDocument = userContext.filters;

  const {
    newUrls,
    setNewUrls,
    showFilter,
    setShowFilter,
    currentFilterUrl,
    setCurrentFilterUrl,
    sortConfig,
    setSortConfig,
    searchTerm,
    setSearchTerm,
    suggestions,
    setSuggestions,
    wordIndex,
    setWordIndex,
    suggestionIndex,
    setSuggestionIndex,
    darkMode,
  } = useTableState();

  const sortedUrls = useSortedUrls(urls, eventsInfo, sortConfig, early);
  const getNewSortConfig = useSortConfigUpdate(sortConfig);

  const handleSort = (key) => {
    const newSortConfig = getNewSortConfig(key);
    setSortConfig(newSortConfig);
  };

  if (!user) return null;

  if (phantomAllowed === false) {
    return <NotAllowed />;
  }

  return (
    <div className="home-page">
      {/* <GlobalMessage show={user.email === mainUser} user={user} /> */}

      {editFilters && showTable && <TicketDrops signOutButton={true} />}

      <Link onClick={handleSignOut(db, user)} className={classes.link}>
        <IconButton
          color="inherit"
          disableRipple
          className={classes.iconButton}
        >
          <img src="/logout.png" alt="logout" className={classes.logoutIcon} />
        </IconButton>
      </Link>
      <AddURLForm
        darkMode={darkMode}
        handleAddUrl={(e) =>
          handleAddUrl(e, newUrls, urls, TotalUrls, setNewUrls, mainUser, db)
        }
        newUrls={newUrls}
        setNewUrls={setNewUrls}
        editFilters={editFilters}
      />

      {!showTable && TotalUrls && (
        <>
          <p style={{ fontSize: "17px", margin: "0", fontWeight: "bold" }}>
            Total URLs: {urls ? urls.length : "0"}
            {TotalUrls ? " - " + TotalUrls : ""}
          </p>
          {totalEarly ? (
            <p
              style={{
                fontSize: "17px",
                margin: "0 0 35px",
                fontWeight: "bold",
              }}
            >
              Total Early Urls: {early ? early.length : "0"}
              {totalEarly ? " - " + totalEarly : ""}
            </p>
          ) : null}

          <SearchBar
            darkMode={darkMode}
            searchTerm={searchTerm}
            handleInputChange={(e) =>
              handleInputChange(
                e,
                eventsInfo,
                setSearchTerm,
                setSuggestions,
                setWordIndex,
                setSuggestionIndex
              )
            }
            handleKeyDown={(e) =>
              handleKeyDown(
                e,
                suggestions,
                setSearchTerm,
                setWordIndex,
                setSuggestionIndex,
                wordIndex,
                suggestionIndex
              )
            }
            suggestions={suggestions}
            suggestionIndex={suggestionIndex}
            wordIndex={wordIndex}
          />

          <UrlTable
            darkMode={darkMode}
            searchResults={searchResults(sortedUrls, eventsInfo, searchTerm)}
            eventsInfo={eventsInfo}
            handleRemoveUrl={(e) =>
              handleRemoveUrl(e, mainUser, db, totalEarly, early)
            }
            sortConfig={sortConfig}
            handleSort={handleSort}
            filtersDocument={filtersDocument}
            handleFilterClick={handleFilterClick(
              setCurrentFilterUrl,
              setShowFilter
            )}
            vividIds={vividIds}
            handleEarlyMonitorToggle={(e) => {
              handleEarlyMonitorToggle(
                e[0],
                e[1],
                mainUser,
                db,
                totalEarly,
                early
              );
            }}
            early={early}
            totalEarly={totalEarly}
            stubhubIds={stubhubIds}
            editFilters={editFilters}
            mutedEvents={mainUser === user.email ? mutedEvents : false}
          />

          {showFilter && !editFilters && (
            <>
              {mainUser === "test@gmail.com" ? (
                <FilterForm
                  eventId={currentFilterUrl[0]}
                  handleClose={handleFilterClose(setShowFilter)}
                  email={mainUser}
                  fullURL={currentFilterUrl[1]}
                  eventInfo={currentFilterUrl[2]}
                  stubhubId={stubhubIds[currentFilterUrl[0]]}
                  filter={filtersDocument}
                  formattedFilters={formattedFiltersRef.current}
                />
              ) : (
                <FilterFormOld
                  url={currentFilterUrl[0]}
                  handleClose={handleFilterClose(setShowFilter)}
                  email={mainUser}
                  fullURL={currentFilterUrl[1]}
                  eventInfo={currentFilterUrl[2]}
                  stubhubId={stubhubIds[currentFilterUrl[0]]}
                  filter={filtersDocument}
                  formattedFilters={formattedFiltersRef.current}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default HomePage;
