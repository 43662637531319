import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import SvgLabelList from "./map/SvgList";
import SvgPaths from "./map/SvgPath";

import "../../css/mapTotal.css";

import FilterHeaders from "./filterTable/filterHeader";

import MapDisplay from "./map";

import FilterInfo from "./filterTable/filterTable";

import { handleSvgPathClick } from "./map/mapUtilities";

import {
  clearAll,
  handleAddRow,
  handleRemoveRow,
  handleSubmit,
  makeFilterSectionTypes,
} from "./filterTable/filterInfoUtilities";
import IconButton from "@material-ui/core/IconButton";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import NotificationDialog from "../NotifcationManager/Overlay";
import { useTableEffects } from "./Effects";
import { useTableState } from "./State";
import { useTheme } from "@emotion/react";
import PhantomLogs from "../LogPage";
import UrlAnalytics from "../urlAnalytics";
import { useEventContext } from "../HomePage/UserDataContext";

const FilterFormOld = ({
  url,
  handleClose,
  email,
  fullURL,
  eventInfo,
  stubhubId,
  filter,
  formattedFilters,
}) => {
  const openNotificationDialog = () => {
    setIsNotificationDialogOpen(true);
  };

  const closeNotificationDialog = () => {
    setIsNotificationDialogOpen(false);
  };

  const theme = useTheme();

  const {
    totalAvailableStock,
    setTotalAvailableStock,
    totalStock,
    setTotalStock,
    ticketTypesList,
    setTicketTypesList,
    rows,
    setRows,
    sections,
    setSections,
    filtersApplied,
    setFiltersApplied,
    selectedPaths,
    setSelectedPaths,
    paths,
    setPaths,
    info,
    setInfo,
    mapid,
    setMapid,
    selectedRow,
    setSelectedRow,
    data,
    setData,
    isNotificationDialogOpen,
    setIsNotificationDialogOpen,
    darkMode,
    axsMapInfo,
    setAxsMapInfo,
    defaultData,
  } = useTableState();

  const userContext = useEventContext();

  const { twentyFiveDay } = userContext;

  const { name, date, venue, priceRange, eventType } = eventInfo;

  useTableEffects(
    data,
    selectedRow,
    setData,
    selectedPaths,
    setSelectedPaths,
    setSelectedRow,
    fullURL,
    setRows,
    setSections,
    setInfo,
    setMapid,
    setPaths,
    url,
    email,
    filtersApplied,
    setFiltersApplied,
    defaultData,
    setTicketTypesList,
    filter,
    twentyFiveDay,
    setTotalStock,
    setTotalAvailableStock,
    setAxsMapInfo,
    eventType
  );

  const isAxs = fullURL.includes("axs");

  const isMlb =
    fullURL.includes("mlb.tickets.com") || fullURL.includes("mpv.tickets.com");

  const isSeatGeek = fullURL.includes("seatgeek.com");

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      key={darkMode ? "dark" : "light"}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
      }}
    >
      <DialogContent>
        <div>
          <IconButton
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 1000,
            }}
            color="primary"
            onClick={openNotificationDialog}
          >
            <VolumeUpIcon />
          </IconButton>

          <UrlAnalytics url={fullURL} mainUser={email} />

          {email === "dcheck1826@gmail.com" || email === "test@gmail.com" ? (
            <PhantomLogs event_id={url} />
          ) : null}

          <NotificationDialog
            open={isNotificationDialogOpen}
            onClose={closeNotificationDialog}
            eventId={url}
          />
        </div>
        <FilterHeaders
          fullURL={fullURL}
          name={name}
          date={
            isAxs
              ? date
              : new Date(date).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
          }
          venue={venue}
          priceRange={priceRange}
          stubhubId={stubhubId}
          makeFilterSectionTypes={() =>
            makeFilterSectionTypes(
              paths,
              setData,
              ticketTypesList,
              twentyFiveDay
            )
          }
          data={data}
        />
        <FilterInfo
          handleSubmit={(e) => {
            handleSubmit(
              e,
              email,
              data,
              sections,
              url,
              handleClose,
              formattedFilters,
              fullURL
            );
          }}
          fullURL={fullURL}
          handleAddRow={() =>
            handleAddRow(
              ticketTypesList,
              defaultData,
              setData,
              setSelectedRow,
              setSelectedPaths,
              Object.keys(data).length,
              twentyFiveDay,
              eventType
            )
          }
          handleRemoveRow={(rowIndex) =>
            handleRemoveRow(
              rowIndex,
              setData,
              selectedRow,
              setSelectedRow,
              setSelectedPaths
            )
          }
          totalAvailableStock={totalAvailableStock}
          totalStock={totalStock}
          twentyFiveDay={twentyFiveDay}
          clearAll={() => clearAll(defaultData, setData)}
          data={data}
          defaultData={defaultData}
          ticketTypesList={ticketTypesList}
          rows={rows}
          sections={sections}
          darkMode={darkMode}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setSelectedPaths={setSelectedPaths}
          setData={setData}
          mainUser={email}
        />

        <MapDisplay
          paths={paths}
          info={info}
          mapid={mapid}
          darkMode={darkMode}
          handleSvgPathClick={(path) =>
            handleSvgPathClick(path, setSelectedPaths)
          }
          selectedPaths={selectedPaths}
          SvgPaths={SvgPaths}
          SvgLabelList={SvgLabelList}
          axs={isAxs}
          axsMapInfo={axsMapInfo}
          setSelectedSections={setSelectedPaths}
          isMlb={isMlb}
          isSeatGeek={isSeatGeek}
        />
      </DialogContent>
    </Dialog>
  );
};

export default FilterFormOld;
