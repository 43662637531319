import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useRef } from "react";
import LZString from "lz-string";

const EventContext = createContext();

export const useEventContext = () => useContext(EventContext);

export const EventProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [mainUser, setMainUser] = useState(null);
  const [eventsInfo, setEventsInfo] = useState({});
  const [stubHubInfo, setStubHubInfo] = useState({});
  const [filters, setFilters] = useState({});
  const [urls, setUrls] = useState([]);
  const [TotalUrls, setTotalUrls] = useState(0);
  const [notesDocument, setNotesDocument] = useState({});
  const [early, setEarly] = useState([]);
  const [totalEarly, setTotalEarly] = useState(0);
  const [mutedEvents, setMutedEvents] = useState({});
  const [editFilters, setEditFilters] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [qEvents, setQEvents] = useState([]);
  const [twentyFiveDay, setTwentyFiveDay] = useState(false);
  const [vividIds, setVividIds] = useState({});
  const [planType, setPlanType] = useState(undefined);
  const [phantomAllowed, setPhantomAllowed] = useState(true);

  const formattedFiltersRef = useRef({});
  const eventInfoRef = useRef({});

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        let mainAccount = currentUser.email;
        const userDocRef = doc(db, "users", currentUser.email);

        const unsubscribeSubUserSnapshot = onSnapshot(
          userDocRef,
          (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userSnapshotData = docSnapshot.data();

              if (userSnapshotData.role === "sub") {
                mainAccount = userSnapshotData.mainAccount;
                setEditFilters(userSnapshotData.settings?.editFilters || false);
                setShowTable(userSnapshotData.settings?.showTable || false);
              } else {
                setEditFilters(false);
                setShowTable(false);
              }
              setMainUser(mainAccount);

              fetchUserSpecificData(
                mainAccount,
                userSnapshotData.role !== "sub"
              );
            }
          }
        );

        return () => unsubscribeSubUserSnapshot();
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (!user || !mainUser) return;

    let sessionSubscribe;
    const timeoutId = setTimeout(() => {
      const sessionDocRef = doc(db, "sessions", user.email);
      sessionSubscribe = onSnapshot(sessionDocRef, async (doc) => {
        if (doc.exists()) {
          const sessionInfo = doc.data();

          const ipAddress = await fetch(
            "https://mg.phantomcheckerapi.com/api-internal/extra/get-ip"
          )
            .then((res) => res.json())
            .then((data) => data.ip);

          const userAgent = navigator.userAgent;

          if (userAgent && userAgent.includes("PhantomChecker")) {
            if (!userAgent.includes("PhantomChecker/1.1.0")) {
              if (!userAgent.includes("PhantomChecker/1.1.1")) {
                if (!userAgent.includes("Mac")) {
                  setPhantomAllowed(false);
                }
              }
            }
          }

          const cookies = document.cookie.split("; ");
          let sessionId = null;
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].split("=");
            if (cookie[0] === "sessionid") {
              sessionId = cookie[1];
            }
          }

          let sessionExists = false;
          for (let i = 0; i < sessionInfo.sessions.length; i++) {
            const session = sessionInfo.sessions[i];
            if (
              session.sessionId === sessionId &&
              session.ipAddress === ipAddress &&
              session.userAgent === userAgent
            ) {
              sessionExists = true;
            }
          }

          if (!sessionExists) {
            document.cookie = "sessionid=;";
            console.log(sessionInfo);
            console.log("Sign out due to session change home", ipAddress);
            auth.signOut();
          }
        } else {
          const ipAddress = await fetch(
            "https://mg.phantomcheckerapi.com/api-internal/extra/get-ip"
          )
            .then((res) => res.json())
            .then((data) => data.ip)
            .catch((error) => {
              console.error("Error fetching IP address: ", error);
            });

          const userAgent = navigator.userAgent;

          const cookies = document.cookie.split("; ");

          let sessionId = null;
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].split("=");
            if (cookie[0] === "sessionid") {
              sessionId = cookie[1];
            }
          }

          const newSession = {
            ipAddress,
            userAgent,
            sessionId,
            timestamp: new Date(),
          };

          setDoc(sessionDocRef, {
            sessions: [newSession],
            totalSessions: 1,
          });
        }
      });
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
      if (sessionSubscribe) sessionSubscribe();
    };
  }, [user, mainUser]);

  const fetchUserSpecificData = (mainAccount, isMainAccount) => {
    const userDoc = doc(db, "users", mainAccount);
    return onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        setUrls(userData.urls?.reverse() || []);
        setTotalUrls(userData.TotalUrls || 0);
        setEarly(userData.early || []);
        setTotalEarly(userData.TotalEarly || 0);
        setMutedEvents(userData.mutedEvents || {});
        setTwentyFiveDay(userData.twentyFiveDollarDay || false);
        if (userData.stripe && isMainAccount) {
          setPlanType("stripe");
        }
        if (userData.whop && isMainAccount) {
          setPlanType("whop");
        }
      }
    });
  };

  useEffect(() => {
    if (!mainUser) return;

    const unsubscribes = [];

    const fetchEventsInfoAndStubHubData = () => {
      if (localStorage.getItem("eventInfo")) {
        try {
          const decodedEventInfoJSON = LZString.decompressFromBase64(
            localStorage.getItem("eventInfo")
          );
          const decodedEventInfo = JSON.parse(decodedEventInfoJSON) || {};
          setEventsInfo(decodedEventInfo);
        } catch (error) {}
      }

      if (localStorage.getItem("filters")) {
        try {
          const decodedFiltersJSON = LZString.decompressFromBase64(
            localStorage.getItem("filters")
          );
          const decodedFilters = JSON.parse(decodedFiltersJSON) || {};
          setFilters(decodedFilters);
        } catch (error) {}
      }

      if (localStorage.getItem("qEvents")) {
        try {
          const decodedQEventsJSON = LZString.decompressFromBase64(
            localStorage.getItem("qEvents")
          );
          const decodedQEvents = JSON.parse(decodedQEventsJSON) || [];
          setQEvents(decodedQEvents);
        } catch (error) {}
      }

      if (localStorage.getItem("stubhubInfo")) {
        try {
          const decodedStubHubInfoJSON = LZString.decompressFromBase64(
            localStorage.getItem("stubhubInfo")
          );
          const decodedStubHubInfo = JSON.parse(decodedStubHubInfoJSON) || {};
          setStubHubInfo(decodedStubHubInfo);
        } catch (error) {}
      }

      if (localStorage.getItem("vividIds")) {
        try {
          const decodedVividIdsJSON = LZString.decompressFromBase64(
            localStorage.getItem("vividIds")
          );
          const decodedVividIds = JSON.parse(decodedVividIdsJSON) || {};
          setVividIds(decodedVividIds);
        } catch (error) {}
      }

      if (localStorage.getItem("notesDocument")) {
        try {
          const decodedNotesDocumentJSON = LZString.decompressFromBase64(
            localStorage.getItem("notesDocument")
          );
          const decodedNotesDocument =
            JSON.parse(decodedNotesDocumentJSON) || {};
          setNotesDocument(decodedNotesDocument);
        } catch (error) {}
      }

      const runQuery = async () => {
        const filtersDoc = collection(db, "filters4");
        const filtersQuery = query(
          filtersDoc,
          where("emailAddress", "==", mainUser)
        );

        await new Promise((r) => setTimeout(r, 5000));

        const unsubscribe = onSnapshot(filtersQuery, (snapshot) => {
          const fetchedFilters = {};
          const formattedFilters = {};
          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            const docId = doc.id;
            Object.keys(data.filter).forEach((eventId) => {
              fetchedFilters[eventId] = data.filter[eventId];
              formattedFilters[docId] = data.filter;
            });
          });

          setFilters(fetchedFilters);

          const filtersJSON = JSON.stringify(fetchedFilters);

          const compressedFilters = LZString.compressToBase64(filtersJSON);

          localStorage.setItem("filters", compressedFilters);

          formattedFiltersRef.current = formattedFilters;
        });

        return unsubscribe;
      };

      runQuery().then((unsubscribe) => {
        unsubscribes.push(unsubscribe);
      });

      const eventInfoCollection = doc(db, "event_info4", mainUser);
      const unsubscribeEventInfo = onSnapshot(
        eventInfoCollection,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const eventInfoData = docSnapshot.data();
            const compressedEventInfo = LZString.compressToBase64(
              JSON.stringify(eventInfoData.eventData)
            );
            localStorage.setItem("eventInfo", compressedEventInfo);
            setEventsInfo(eventInfoData.eventData);
            eventInfoRef.current = eventInfoData.eventData;
          } else {
            setEventsInfo({});
          }
        },
        (error) => {
          console.error("Error fetching event info document: ", error);
        }
      );

      unsubscribes.push(unsubscribeEventInfo);

      const qEventsDoc = doc(db, "QEvents", "info");
      const unsubscribeQEvents = onSnapshot(qEventsDoc, (docSnapshot) => {
        const data = docSnapshot.data();
        const compressedQEvents = LZString.compressToBase64(
          JSON.stringify(data.urls)
        );
        localStorage.setItem("qEvents", compressedQEvents);
        setQEvents(data.urls || []);
      });

      unsubscribes.push(unsubscribeQEvents);

      const stubhubDoc = doc(db, "stubhubID", "info");
      const unsubscribeStubHub = onSnapshot(stubhubDoc, (docSnapshot) => {
        const data = docSnapshot.data() || {};
        const compressedStubHubInfo = LZString.compressToBase64(
          JSON.stringify(data)
        );
        localStorage.setItem("stubhubInfo", compressedStubHubInfo);
        setStubHubInfo(data);
      });

      unsubscribes.push(unsubscribeStubHub);

      const vividDoc = doc(db, "vividID", "info");
      const unsubscribeVivid = onSnapshot(vividDoc, (docSnapshot) => {
        const data = docSnapshot.data().urls || {};
        const compressedVividIds = LZString.compressToBase64(
          JSON.stringify(data)
        );
        localStorage.setItem("vividIds", compressedVividIds);
        setVividIds(data);
      });

      unsubscribes.push(unsubscribeVivid);

      const eventNoteDocRef = doc(db, "eventNotes2", mainUser);
      const unsubscribeEventNotes = onSnapshot(
        eventNoteDocRef,
        (querySnapshot) => {
          const querySnapshotData = querySnapshot.data();
          if (querySnapshotData) {
            const data = querySnapshotData.notes || {};
            const compressedNotesDocument = LZString.compressToBase64(
              JSON.stringify(data)
            );
            localStorage.setItem("notesDocument", compressedNotesDocument);
            setNotesDocument(data);
          }
        }
      );

      unsubscribes.push(unsubscribeEventNotes);
    };

    fetchEventsInfoAndStubHubData();

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [mainUser]);

  const value = {
    user,
    eventsInfo,
    stubHubInfo,
    filters,
    urls,
    TotalUrls,
    notesDocument,
    early,
    totalEarly,
    mutedEvents,
    qEvents,
    // showMessage,
    editFilters,
    showTable,
    mainUser,
    eventInfoRef,
    formattedFiltersRef,
    twentyFiveDay,
    vividIds,
    setTwentyFiveDay,
    planType,
    phantomAllowed,
  };

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};

export default EventProvider;
