import { useTableEffects } from "./_hooks/useTableEffects";
import { useTableState } from "./_hooks/useTableState";
import { useEventContext } from "../HomePage/UserDataContext";
import { handleAddRow, handleSubmit } from "./_utils/events";
import { TransformWrapper } from "react-zoom-pan-pinch";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import CloseIcon from "@material-ui/icons/Close";
import NotificationDialog from "../NotifcationManager/Overlay";
import UrlAnalytics from "../urlAnalytics";
import Dialog from "./_components/Dialog";
import EventInfo from "./_components/blocks/EventInfo";
import FilterTable from "./_components/blocks/FilterTable";
import NotesSection from "./_components/blocks/NotesSection";
import ExtraFilterOptions from "./_components/blocks/ExtraFilterOptions";
import MapSection from "./_components/blocks/MapSection";
import "../../css/mapTotal.css";

export default function FilterForm({
  eventId,
  handleClose,
  email,
  fullURL,
  eventInfo,
  stubhubId,
  filter,
  formattedFilters,
}) {
  const { tableState, setTableState, darkMode, defaultData } = useTableState();
  const { twentyFiveDay, notesDocument, vividIds } = useEventContext();

  const { name, date, venue, eventType } = eventInfo;
  useTableEffects(
    tableState,
    setTableState,
    defaultData,
    fullURL,
    eventId,
    filter,
    twentyFiveDay,
    eventType
  );

  const isAxs = fullURL.includes("axs");
  const isMlb =
    fullURL.includes("mlb.tickets.com") || fullURL.includes("mpv.tickets.com");
  const isSeatGeek = fullURL.includes("seatgeek.com");

  const vividUrl = vividIds[eventId] ?? "";

  return (
    <Dialog open={true} onClose={handleClose}>
      <NotificationDialog
        open={tableState.isNotificationDialogOpen}
        onClose={() => setTableState({ isNotificationDialogOpen: false })}
        eventId={eventId}
      />
      <Dialog.Header>
        <div className="flex items-center space-x-3 w-52">
          <UrlAnalytics url={fullURL} mainUser={email} />
          <VolumeUpIcon
            fontSize="large"
            className="bg-black/30 dark:bg-white/20 p-1.5 rounded-full border hover:bg-black/25 dark:hover:bg-transparent transition-all duration-300 ease-in-out cursor-pointer border-white/20"
            onClick={() => setTableState({ isNotificationDialogOpen: true })}
          />
        </div>
        <EventInfo name={name} date={date} venue={venue} />
        <div className="flex items-center justify-end w-52">
          <CloseIcon
            fontSize="large"
            className="bg-black/30 dark:bg-white/20 p-1.5 rounded-full border hover:bg-black/25 dark:hover:bg-transparent transition-all duration-300 ease-in-out cursor-pointer border-white/20"
            onClick={handleClose}
          />
        </div>
      </Dialog.Header>
      <Dialog.Body>
        <div className="flex flex-col min-h-full gap-5">
          <FilterTable
            tableState={tableState}
            setTableState={setTableState}
            onAddFilter={() =>
              handleAddRow(
                defaultData,
                tableState,
                setTableState,
                twentyFiveDay,
                false,
                false,
                eventType
              )
            }
            onSaveFilter={(e) =>
              handleSubmit(
                e,
                tableState,
                email,
                eventId,
                handleClose,
                formattedFilters,
                fullURL
              )
            }
          />
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            <MapSection
              className="flex xl:hidden"
              tableState={tableState}
              setTableState={setTableState}
              stubhubId={stubhubId}
              vividUrl={vividUrl}
              darkMode={darkMode}
              isAxs={isAxs}
              isMlb={isMlb}
              isSeatGeek={isSeatGeek}
            />
          </TransformWrapper>

          <div className="mt-4 xl:mt-[88px]">
            <div className="flex xl:items-start w-full justify-center xl:justify-between gap-10">
              <div className="hidden lg:block w-1/2">
                <NotesSection
                  notesDocument={notesDocument}
                  userEmail={email}
                  eventId={eventId}
                />
              </div>
              <ExtraFilterOptions
                tableState={tableState}
                setTableState={setTableState}
                defaultData={defaultData}
                twentyFiveDay={twentyFiveDay}
                fullURL={fullURL}
              />
            </div>
          </div>
        </div>

        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
        >
          <MapSection
            className="hidden xl:flex"
            tableState={tableState}
            setTableState={setTableState}
            stubhubId={stubhubId}
            vividUrl={vividUrl}
            darkMode={darkMode}
            isAxs={isAxs}
            isMlb={isMlb}
            isSeatGeek={isSeatGeek}
          />
        </TransformWrapper>
      </Dialog.Body>
    </Dialog>
  );
}
