import DownloadAppDialog from "../Login/DownloadApp";

const NotAllowed = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1e1e2f",
      }}
    >
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#2b2b3a",
        }}
      >
        <h1 style={{ color: "white", marginBottom: "20px", fontSize: "24px" }}>
          We are happy to announce that we have released a new version of the
          windows app. This will fix a lot of issues.
          <br />
          Please download the newest version to continue using Phantom Checker.
          <br />
          Mac update coming soon. :)
        </h1>
        <DownloadAppDialog Issue={false} />
      </div>
    </div>
  );
};

export default NotAllowed;
