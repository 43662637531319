import { collection, doc, writeBatch } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { organizeSections } from "../map/mapUtilities";

export const handleAddRow = (
  ticketTypesList,
  defaultData,
  setData,
  setSelectedRow,
  setSelectedPaths,
  totalRows,
  twentyFiveDay,
  eventType
) => {
  let tickets = [
    "Verified Resale",
    "Official Platinum",
    "Special Offers",
    "VIP",
    "Wheel Chair",
    "Make A Difference Ticket",
    "Platinum",
    "Diamond",
    "Gold",
    "Silver",
    "Resale",
    "Premium",
    "Prime",
    "Accessibility",
  ];

  if (twentyFiveDay) {
    tickets.push("Standard");
  }

  if (eventType && eventType !== "") {
    tickets = tickets.filter((type) => type !== "Resale");
  }

  const additionalTicketTypes = ticketTypesList.filter((type) =>
    tickets.some((vipType) => type.includes(vipType))
  );

  const newData = {
    ...defaultData,
    ticketTypes:
      additionalTicketTypes.length > 0
        ? additionalTicketTypes
        : defaultData.ticketTypes,
  };

  setData((prevData) => [...prevData, newData]);
  setSelectedRow(totalRows);
  setSelectedPaths([]);
};

export const handleRemoveRow = (
  rowIndex,
  setData,
  selectedRow,
  setSelectedRow,
  setSelectedPaths
) => {
  setData((prevData) => {
    if (rowIndex < 0 || rowIndex >= prevData.length) {
      return prevData;
    }

    const updatedData = [...prevData];
    updatedData.splice(rowIndex, 1);

    const newSelectedRow =
      selectedRow < updatedData.length ? selectedRow : updatedData.length - 1;

    setSelectedRow(newSelectedRow);
    setSelectedPaths(updatedData[newSelectedRow]?.sections || []);

    return updatedData;
  });
};

export const clearAll = (defaultData, setData) => {
  setData([{ ...defaultData }]);
};

export const makeFilterSectionTypes = (
  paths,
  setData,
  ticketTypesList,
  twentyFiveDay
) => {
  const organizedSections = organizeSections(paths);

  let tickets = [
    "Verified Resale",
    "Official Platinum",
    "Special Offers",
    "VIP",
    "Wheel Chair",
    "Make A Difference Ticket",
    "Platinum",
    "Diamond",
    "Gold",
    "Silver",
    "Resale",
    "Prime",
    "Premium",
    "Accessibility",
  ];

  if (twentyFiveDay) {
    tickets.push("Standard");
  }

  const additionalTicketTypes = ticketTypesList.filter((type) =>
    tickets.some((vipType) => type.includes(vipType))
  );

  const newData = Object.keys(organizedSections).map((key) => ({
    excludeFilter: false,
    sections: organizedSections[key].map((sec) => sec.name),
    rows: [],
    prices: [{ min: 0, max: "" }],
    numSeats: 2,
    ticketTypes: additionalTicketTypes.length > 0 ? additionalTicketTypes : [],
  }));

  setData(newData);
};

export const fetchData = (
  event_id,
  setData,
  setFiltersApplied,
  defaultData,
  setSelectedPaths,
  contextFilters
) => {
  try {
    const eventFilters = contextFilters[event_id];

    if (eventFilters) {
      if (Object.keys(eventFilters).length > 0) {
        const eventFiltersCopy = JSON.parse(JSON.stringify(eventFilters));

        const sortedData = Object.keys(eventFiltersCopy)
          .sort()
          .map((key) => {
            if (typeof eventFiltersCopy[key].prices === "string") {
              eventFiltersCopy[key].prices = [
                { min: 0, max: eventFiltersCopy[key].prices },
              ];
            }
            return eventFiltersCopy[key];
          });

        setData(sortedData);
        setData(sortedData);

        setData(sortedData);

        setSelectedPaths(sortedData[0]?.sections || []);
        setSelectedPaths(sortedData[0]?.sections || []);

        setSelectedPaths(sortedData[0]?.sections || []);

        setFiltersApplied(true);
      } else {
        setFiltersApplied(false);
      }
    } else {
      setData([defaultData]);
      setFiltersApplied(false);
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};

export const handleSubmit = async (
  e,
  email,
  data,
  sections,
  event_id,
  handleClose,
  formattedFilters,
  fullURL
) => {
  try {
    e.preventDefault();

    // Process the new data
    const updatedFilters = data.reduce((acc, cur, i) => {
      const currentSectionNames = cur.sections;
      const matchingSections = sections.filter((section) =>
        currentSectionNames.includes(section.sectionName)
      );
      const matchingSectionIds = matchingSections.map((section) => section.id);
      const timestamp = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });

      const randomId = Math.floor(Math.random() * 1000000);

      acc[`row${i + 1}`] = {
        ...cur,
        sectionIds: matchingSectionIds,
        timestamp: timestamp,
        id: randomId,
      };
      return acc;
    }, {});

    const isInvalidPriceRange = data.some((item) =>
      item.prices.some(
        (price) => Number(price.min) === Number(price.max) && price.max !== ""
      )
    );

    if (isInvalidPriceRange) {
      alert("Minimum and maximum prices cannot be the same.");
      return;
    }

    let targetDocID = null;
    let docWithSpace = null;

    Object.entries(formattedFilters).forEach(([docId, filterData]) => {
      if (filterData.hasOwnProperty(event_id)) {
        targetDocID = docId;
        console.log("targetDocID: ", targetDocID);
      } else if (!docWithSpace && Object.keys(filterData).length < 100) {
        docWithSpace = docId;
      }
    });

    const batch = writeBatch(db);

    const filtersCollection = collection(db, "filters4");
    const docId = `${email}-${event_id}`;
    const urlLogsDoc = doc(db, "UrlLogs", docId);

    if (targetDocID) {
      const docToUpdate = doc(filtersCollection, targetDocID);
      batch.update(docToUpdate, {
        [`filter.${event_id}`]: updatedFilters,
        last_modified: new Date(),
      });
    } else if (docWithSpace) {
      const docToUpdate = doc(filtersCollection, docWithSpace);
      batch.update(docToUpdate, {
        [`filter.${event_id}`]: updatedFilters,
        last_modified: new Date(),
      });
    } else {
      const newDocRef = doc(filtersCollection);
      batch.set(newDocRef, {
        last_modified: new Date(),
        emailAddress: email,
        filter: { [event_id]: updatedFilters },
      });
    }

    batch.set(
      urlLogsDoc,
      {
        urlFilters: {
          [new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
          })]: {
            e: fullURL,
            u: auth.currentUser.email,
          },
        },
      },
      { merge: true }
    );

    await batch.commit();
    handleClose();
  } catch (error) {
    console.error("Error handling submission: ", error);
  }
};

export const arraysEqual = (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;

  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  a.sort();
  b.sort();

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
