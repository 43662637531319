import { useEffect, useRef } from "react";
import { fetchSectionRow, fetchTicketTypes } from "./map/mapUtilities";
import { arraysEqual, fetchData } from "./filterTable/filterInfoUtilities";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

export const useTableEffects = (
  data,
  selectedRow,
  setData,
  selectedPaths,
  setSelectedPaths,
  setSelectedRow,
  fullURL,
  setRows,
  setSections,
  setInfo,
  setMapid,
  setPaths,
  url,
  email,
  filtersApplied,
  setFiltersApplied,
  defaultData,
  setTicketTypesList,
  filters,
  twentyFiveDay,
  setTotalStock,
  setTotalAvailableStock,
  setAxsMapInfo,
  eventType
) => {
  useEffect(() => {
    if (
      data &&
      data.length > 0 &&
      selectedRow !== null &&
      data[selectedRow] &&
      !arraysEqual(selectedPaths, data[selectedRow]?.sections)
    ) {
      setData((prevData) => {
        if (!arraysEqual(prevData[selectedRow].sections, selectedPaths)) {
          const updatedData = [...prevData];
          updatedData[selectedRow].sections = [...selectedPaths];
          return updatedData;
        }
        return prevData;
      });
    }
    // eslint-disable-next-line
  }, [selectedPaths]);

  const prevDataRef = useRef();
  const prevSelectedRowRef = useRef();

  const firstCall = useRef(true);

  useEffect(() => {
    const prevData = prevDataRef.current;
    const prevSelectedRow = prevSelectedRowRef.current;

    if (
      data !== prevData ||
      selectedRow !== prevSelectedRow ||
      !arraysEqual(data[selectedRow]?.sections || [], selectedPaths)
    ) {
      if (data[selectedRow]?.sections) {
        setSelectedPaths(data[selectedRow].sections);
      } else if (data.length === 0) {
        setSelectedPaths([]);
      } else if (data.length > 0 && selectedRow >= data.length) {
        setSelectedRow(0);
        setSelectedPaths([]);
      }
    }

    prevDataRef.current = data;
    prevSelectedRowRef.current = selectedRow;

    // eslint-disable-next-line
  }, [selectedRow]);

  useEffect(() => {
    if (firstCall.current) {
      fetchSectionRow(
        fullURL,
        setRows,
        setSections,
        setInfo,
        setMapid,
        setPaths,
        setTotalStock,
        setAxsMapInfo
      );
      fetchTicketTypes(fullURL);
      firstCall.current = false;
    }
    fetchData(
      url,
      setData,
      setFiltersApplied,
      defaultData,
      setSelectedPaths,
      filters
    );
    const inventory_ref = doc(db, "inventory_types", url);

    const unsubscribe = onSnapshot(inventory_ref, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        let ticketTypesFromFirestore = data["types"] || [];

        setTotalAvailableStock(data["sectionStock"] || {});

        setTicketTypesList(ticketTypesFromFirestore);

        if (!filtersApplied) {
          let additionalTicketTypes = [
            "Verified Resale",
            "Official Platinum",
            "Special Offers",
            "VIP",
            "Wheel Chair",
            "Make A Difference Ticket",
            "Platinum",
            "Diamond",
            "Gold",
            "Silver",
            "Resale",
            "Prime",
            "Premium",
            "Accessibility",
          ];

          if (twentyFiveDay) {
            additionalTicketTypes.push("Standard");
          }

          if (eventType && eventType !== "") {
            additionalTicketTypes = additionalTicketTypes.filter(
              (type) => type !== "Resale"
            );
          }

          const matchingTicketTypes = ticketTypesFromFirestore.filter((tt) =>
            additionalTicketTypes.some((type) => tt.includes(type))
          );

          if (matchingTicketTypes.length > 0) {
            setData((prevData) =>
              prevData.map((item) => ({
                ...item,
                ticketTypes: [...item.ticketTypes, ...matchingTicketTypes],
              }))
            );
          }
        }
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [url, email, fullURL, filtersApplied]);
};
